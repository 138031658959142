import React from 'react';
import {
  addPresetStyles,
  addLayoutStyles,
  GALLERY_CONSTS,
  isEligibleForLeanGallery,
  LayoutFixer,
} from 'pro-gallery';
import '../styles/dynamic/common/GalleryWrapperWixStyles.scss';
import { experimentsWrapper, window } from '@wix/photography-client-lib';
import { utils } from '../utils/webUtils';
import SiteHelper from './helpers/blueprints_siteHelper'; // ~35kb
import DimensionsHelper from './helpers/blueprints_dimensionsHelper'; // ~13kb
import FullscreenHelper from './helpers/fullscreenHelper'; // ~5kb
import LogHelper from './helpers/logHelper'; // ~12kb
import ItemsHelper from './helpers/blueprints_itemsHelper'; // ~113kb (!)
import ItemActionsHelper from './helpers/itemActionsHelper'; // ~113kb (!)
import AccessibilityHelper from './helpers/blueprints_accessibilityHelper'; // ~1kb
import ProGalleryTestIdentifier from './ProGalleryTestIdentifier';
import SyncEventHandler from './helpers/blueprints_syncEventHandler';

class Deferred {
  // BLUEPRINTS REFACTOR - make sure this code is reusable from utils and not written 4 times
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
export default class CommonGalleryWrapper extends React.Component {
  constructor(props) {
    props = { ...props.host, ...props }; // untill props.host will be fully active sv_addPropsToHostInNativeComponent
    super(props);
    if (utils.isVerbose()) {
      console.log(
        '>>>>>>>>>>>>>>>>>>>>THIS IS THE BLUEPRINTS COMMON GALLERY WRAPPER<<<<<<<<<<<<<<<<<<<',
      );
    }
    this.initExperimentFunctions(props);
    this.avoidGallerySelfMeasure = true; // the wrapper is measuring for the gallery
    this.state = {
      fullscreen: {
        clickedIdx: props.clickedIdx >= 0 ? props.clickedIdx : -1,
        fullscreenAnimating: false,
        directFullscreenItem:
          !window.firstProGalleryRenderWithFullscreen &&
          props.directFullscreenItem,
      },
      isAccessible: false,
      itemsLoveData: {},
      bannerHeight: 0,
    };
    // ---------- dont change the order or else (unless you know what you are doing) ------------ //
    const isStoreGallery = this.isStoreGallery();
    this.itemsHelper = new ItemsHelper(this, props, isStoreGallery);
    this.siteHelper = new SiteHelper(this, props, isStoreGallery);
    // this.dimensionsHelper = new DimensionsHelper(this, props);
    this.logHelper = new LogHelper(this, props, this.getSentryDSN());
    this.itemActionsHelper = new ItemActionsHelper(this, props, isStoreGallery);
    this.fullscreenHelper = new FullscreenHelper(this, props, isStoreGallery);
    this.accessibilityHelper = new AccessibilityHelper(this, props);
    this.syncEventHandler = new SyncEventHandler(this, props);
    this.clientSideFunctionsConnectedPromise = new Deferred();
    this.onNewProps(props);

    if (props.directFullscreenItem) {
      window.firstProGalleryRenderWithFullscreen = true;
    }
  }

  initExperimentFunctions(props) {
    this.isLeanGalleryAllowed = () =>
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.allowLeanGallery',
      );
    this.shouldChangeParentHeight = () =>
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.loadMoreClickedGalleryHeight',
      );
    this.shouldUseCustomElement = () =>
      (props.queryParams && props.queryParams.useCustomElement === 'true') ||
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.useCustomElement',
      );
    this.shouldUseLayoutFixer = () =>
      (props.queryParams && props.queryParams.useLayoutFixer === 'true') ||
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.useLayoutFixer',
      );
    this.disableSSROpacity = () =>
      props.queryParams && props.queryParams.disableSSROpacity === 'true';
  }

  // return true if art-store and false for pro-gallery
  isStoreGallery() {
    return false;
  }
  // fullscreen wrapper for OOI apps
  getFullscreenWrapperElement() {
    return null;
  }
  // sentry dsn for the app
  getSentryDSN() {
    return '';
  }
  // item resizer - with watermark for art-store
  getItemResizer() {
    return null;
  }
  // props that are passed for fullscreen wrapper
  getArtStoreProps() {
    return {};
  }
  // watermark for pro-gallery (if false no watermark will be send)
  getWatermark() {
    return false;
  }
  // get pro gallery element (artstore adding banner)
  getProGalleryElement(ProGalleryElement) {
    const wrappedProGalleryElement = this.getWrappedProGalleryIfNeeded(
      ProGalleryElement,
    );
    return wrappedProGalleryElement || ProGalleryElement;
  }

  getWrappedProGalleryIfNeeded() {
    return false;
  }

  getFullscreenSelectedIndex() {
    // I WANT THIS GONE!! but i need to workaround the can render of the fullscreen...
    return this.state.fullscreen.directFullscreenItem &&
      this.state.fullscreen.directFullscreenItem.itemId
      ? 0
      : this.state.fullscreen.clickedIdx;
  }

  // end of common methods
  componentDidMount() {
    this.accessibilityHelper.initAccessibility();
    this.dimensionsHelper = new DimensionsHelper(this);
    this.dimensionsHelper.createResizeObserver();
    this.dimensionsHelper.createIntersectionObserver();

    this.onNewProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.onNewProps(props);
  }

  componentWillUnmount() {
    this.accessibilityHelper.cleanupAccessibility();
  }

  onNewProps(props) {
    props = { ...props.host, ...props }; // untill props.host will be fully active sv_addPropsToHostInNativeComponent
    if (props.clientSetProps) {
      this.clientSideFunctionsConnectedPromise.resolve();
    }
    this.viewMode = this.siteHelper.parseViewMode(props.viewMode);
    this.formFactor = this.siteHelper.parseFormFactor(props.formFactor);
    this.syncEventHandler.update(props);
    this.dimensionsHelper && this.dimensionsHelper.update(props); // will not happen before init in didMount
    this.siteHelper.update(props);
    this.fullscreenHelper.update(props);
    this.logHelper.update(props);
    this.itemsHelper.update(props);
    this.itemsHelper.loadItemsDimensionsIfNeeded();
    this.itemActionsHelper.update(props);
    this.accessibilityHelper.update(props);
    !this.isStoreGallery() && this.setNewDirectFullscreenIfNeeded(props);
  }

  setNewDirectFullscreenIfNeeded(props) {
    if (!props.directFullscreenItem) {
      return;
    }
    if (
      props.directFullscreenItem.itemId &&
      (!this.state.fullscreen.directFullscreenItem ||
        this.state.fullscreen.directFullscreenItem.itemId !==
          props.directFullscreenItem.itemId)
    ) {
      !window.firstProGalleryRenderWithFullscreen &&
        this.setState({
          fullscreen: {
            ...this.state.fullscreen,
            directFullscreenItem: props.directFullscreenItem,
          },
        });
      window.firstProGalleryRenderWithFullscreen = true;
    }
  }

  canRender({
    notInView,
    galleryId,
    styles,
    itemsProps,
    container,
    structure,
  }) {
    if (
      utils.isSSR() &&
      this.viewMode !== GALLERY_CONSTS.viewMode.SEO &&
      notInView
    ) {
      if (utils.isVerbose()) {
        console.log('PG not in view, skipping');
      }
      // for this case, reportAppLoaded is already called in the viewerScript
      return false;
    }
    if (!styles || !itemsProps.items || !container || !structure) {
      return false;
    }

    if (this.itemsHelper.areOneOrMoreItemsCorrupted(itemsProps.items)) {
      console.error('Gallery Wrapper, one or more items are corrupted');
      if (typeof this.props.sentryReport === 'function') {
        const error =
          'Gallery Wrapper, one or more items are corrupted. galleryId = ' +
          galleryId +
          ' items = ' +
          itemsProps.items;
        this.props.sentryReport(error);
      }
      this.logHelper.onAppLoaded();
      return false;
    }

    if (
      styles.oneRow &&
      (container.height === undefined || container.height <= 0)
    ) {
      // for this case, the height is 0 at the beginning, and will be set later.
      // so the appLoaded will be reported as usual, but in next renders
      return false;
    }

    return true;
  }

  isUnKnownContainer(container) {
    const { width, height, isDefaultContainer } = container;
    return (
      !!isDefaultContainer ||
      typeof width !== 'number' ||
      typeof height !== 'number'
    );
  }

  isPrerenderMode(isLeanGallery, container) {
    const isUnKnownContainer = this.isUnKnownContainer(container);
    const isSEO = this.viewMode === GALLERY_CONSTS.viewMode.SEO;
    return !isLeanGallery && isUnKnownContainer && !isSEO;
  }

  getPrerenderViewModeIfNeeded(isPrerenderMode) {
    if (isPrerenderMode) {
      return { viewMode: GALLERY_CONSTS.viewMode.PRERENDER };
    } else {
      return {};
    }
  }

  render() {
    const cssBaseUrl = utils.isDev()
      ? 'https://localhost:3200/'
      : this.props.cssBaseUrl;
    const props = { ...this.props.host, ...this.props }; // until props.host will be fully active sv_addPropsToHostInNativeComponent
    if (this.viewMode === GALLERY_CONSTS.viewMode.PREVIEW) {
      if (!props.propFromSetPropsIndicator) {
        // if this prop do not exist in the component (commonGalleryWrapper), it means that setProps was not called yet
        return null; // if setProps was not called yet, we don't want to render. For now in preview only
      }
    }
    const {
      queryParams,
      notInView,
      id,
      galleryId,
      forceHover,
      pageUrl,
    } = props;

    const { styles, items, container, structure, totalItemsCount } = props;

    const itemsProps = {
      items,
      totalItemsCount,
    };

    const useCustomElement = this.shouldUseCustomElement();
    const useLayoutFixer = this.shouldUseLayoutFixer();
    const noItemsToRender =
      !itemsProps || !itemsProps.items || itemsProps.items.length === 0;

    if (
      utils.isSSR() &&
      (noItemsToRender ||
        experimentsWrapper.getExperimentBoolean('specs.pro-gallery.skipSsr') ||
        (queryParams && queryParams.skipPgSsr === 'true'))
    ) {
      console.error('Skipping Pro Gallery SSR!', this.props);
      return (
        <div
          id={`gallery-wrapper-${id}`}
          key={`gallery-wrapper-${id}`}
          style={{ overflow: 'hidden', height: `100%`, width: '100%' }}
        />
      );
    }

    if (utils.isVerbose()) {
      console.log('Pro Gallery wrapper!', this.props);
      console.count('[OOISSR] proGallery ooi wrapper render');
    }

    this.commonProps = {
      domId: id,
      galleryId,
      allowSSR: true,
      container,
      ...itemsProps,
      forceHover,
      noFollowForSEO: !this.siteHelper.isPremiumSite(),
      formFactor: this.formFactor,
      viewMode: this.viewMode,
      scrollingElement: this.siteHelper.getScrollingElement(),
      itemsLoveData: this.state.itemsLoveData, // can be removed cause the custom renderers are receiving it already
      resizeMediaUrl: this.getItemResizer(),
      pageUrl,
    };
    const disableSSROpacity = this.disableSSROpacity();

    this.pgProps = {
      ...this.commonProps,
      structure,
      useBlueprints: true, // TODO remove - check that the core entry works
      ...this.itemsHelper.getExternalInfoRenderers(),
      eventsListener: this.syncEventHandler.eventHandler,
      settings: {
        avoidInlineStyles: !(useCustomElement || useLayoutFixer),
        disableSSROpacity,
      },
      styles, // need to be the same name as the fullscreen is expecting...
    };

    this.fullscreenProps = {
      ...this.commonProps,
      styleParams: styles, // TODO - need to unify names - see above (is a common object just named wrong)
      scrollTo: this.props.scrollTo,
      fullscreenAnimating: this.state.fullscreen.fullscreenAnimating,
      fullscreenIdx: this.state.fullscreen.clickedIdx,
      animationDuration: this.state.fullscreen.animationDuration,
      eventsListener: this.syncEventHandler.fullscreenEventHandler,
      getPreviewMobileEmulatorWidth: this.siteHelper
        .getPreviewMobileEmulatorWidth, // BLUEPRINTS REFACTOR - this can be taken from the container in preview. no need for other functions.
      getPreviewMobileEmulatorLeft: this.siteHelper
        .getPreviewMobileEmulatorLeft, // BLUEPRINTS REFACTOR - this can be taken from the container in preview. no need for other functions.
      ...this.getArtStoreProps(),
      ...this.fullscreenHelper.directFullscreenItemProps(),
    };

    if (this.getWatermark()) {
      this.pgProps.watermark = this.getWatermark();
    }

    const GalleryComponent = this.getGalleryComponent();
    let dom = [];
    let dangerouslySetInnerHTMLObject;
    if (
      this.canRender({
        notInView,
        galleryId,
        styles,
        itemsProps,
        container,
        structure,
      })
    ) {
      const staticCssFile = this.isStoreGallery()
        ? 'artStoreStaticCss.min.css'
        : 'staticCss.min.css';
      dom.push(
        <link
          key="static-link"
          rel="stylesheet"
          href={`${cssBaseUrl}${staticCssFile}`}
        />,
      );

      const isLeanGallery =
        this.isLeanGalleryAllowed() && isEligibleForLeanGallery(this.pgProps);
      const isPrerenderMode = this.isPrerenderMode(isLeanGallery, container);

      if (
        (useCustomElement || useLayoutFixer) &&
        !isLeanGallery &&
        isPrerenderMode
      ) {
        const customElementProps = {
          'data-parent-id': 'gallery-wrapper-' + id,
        };
        if (utils.isSSR()) {
          Object.assign(customElementProps, {
            'data-items': JSON.stringify(
              itemsProps.items.map((item) => ({
                id: item.itemId,
                width: item.metaData.width,
                height: item.metaData.height,
              })),
            ),
            'data-options': JSON.stringify(styles),
          });
        }
        if (useCustomElement) {
          dom.push(<wix-pro-gallery {...customElementProps} />);
        } else if (useLayoutFixer) {
          dom.push(
            <LayoutFixer
              layoutFixerBundleUrl={`${cssBaseUrl}layoutFixer.bundle.${
                utils.isDev() ? '' : 'min.'
              }js`}
              parentId={'gallery-wrapper-' + id}
              styles={addLayoutStyles(addPresetStyles(styles))}
              items={itemsProps.items}
            />,
          );
        }
      }

      dom.push(
        <ProGalleryTestIdentifier
          key="pro-gallery-test-ident"
          testType={this.props.testType}
        />,
        this.getProGalleryElement(
          <GalleryComponent
            key="pro-gallery"
            ref={(node) => (this.node = node)}
            {...this.pgProps}
            {...this.getPrerenderViewModeIfNeeded(isPrerenderMode)}
          />,
        ),
      );
      const ProFullscreenWrapper = this.getFullscreenElementIfNeeded();
      ProFullscreenWrapper &&
        dom.push(
          <ProFullscreenWrapper
            key="pro-fullscreen"
            {...this.fullscreenProps}
          />,
        );

      const responsiveGallery =
        (styles.oneRow ||
          (!styles.oneRow &&
            styles.enableInfiniteScroll === false &&
            !this.loadMoreClicked)) &&
        !(this.props.host.dimensions.height > 0);
      dangerouslySetInnerHTMLObject = responsiveGallery
        ? {
            __html: `div.${id} {
        height: 100%;
        width: 100%;
        position: relative;
      }
      div.${id} > div {
        position: absolute;
        top: 0;
        left: 0;
      }`,
          }
        : {
            __html: `div.${id} {
      width: 100%;
      }
      ${
        this.shouldChangeParentHeight()
          ? `#${id}{
          height: auto;
        }`
          : ''
      }
      `,
          };
    } else {
      dom = null;
    }

    return (
      <div
        id={`gallery-wrapper-${id}`}
        key={`gallery-wrapper-${id}`}
        style={{ overflow: 'hidden', height: `100%`, width: '100%' }}
      >
        <style dangerouslySetInnerHTML={dangerouslySetInnerHTMLObject} />
        {dom}
      </div>
    );
  }
}
